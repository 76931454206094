import React from "react";
import AOS from "aos";

import "@fontsource/dosis";
import "@fontsource/dosis/400.css";
// import "@fontsource/dosis/400-italic.css";

import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/open-sans/400.css"; // Specify weight
import "@fontsource/open-sans/400-italic.css"; // Specify weight and style

// import "./styles/fontdetail.css";
// import "./styles/fontopen.css";

import "../node_modules/aos/dist/aos.css";
import "./styles/bootstrap.min.css";
// import "animate.css";
import "./styles/flaticon.css";
import "./styles/fontawesome.min.css";

import "swiper/css";
import "swiper/css/bundle";

// Global Styles

import "./styles/style.css";
import "./styles/responsive.css";
import "./styles/developer.css";

// import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import RoutesComponent from "./components/Common/RoutesComponent";

ReactGA.initialize("G-Q1D6RWZFMW");
function App() {
    React.useEffect(() => {
        AOS.init();
    }, []);

    // const location = useLocation();
    // const router = location.pathname;
    // React.useEffect(() => {
    //     ReactGA.send({
    //         hitType: "pageview",
    //         page: router,
    //         title: "Custom Title",
    //     });
    // }, [router]);

    return <RoutesComponent />;
}

export default App;
