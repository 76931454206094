import React from "react";
import { Route, Routes } from "react-router-dom";

const Home = React.lazy(() => import("../../pages/Home.js"));
const Navbar = React.lazy(() => import("../Layouts/Navbar.js"));
const Footer = React.lazy(() => import("../Layouts/Footer.js"));
const GoTop = React.lazy(() => import("../Shared/GoTop.js"));
const Career = React.lazy(() => import("../../pages/career.js"));
const About = React.lazy(() => import("../../pages/about.js"));
const Team = React.lazy(() => import("../../pages/team.js"));
const Partner = React.lazy(() => import("../../pages/partner.js"));
const Feedback = React.lazy(() => import("../../pages/feedback.js"));
const ComingSoon = React.lazy(() => import("../../pages/coming-soon.js"));
const Projects = React.lazy(() => import("../../pages/case-study/index.js"));
const Faq = React.lazy(() => import("../../pages/faq.js"));
const Contact = React.lazy(() => import("../../pages/contact.js"));
const Cmb = React.lazy(() => import("../../pages/cmb.js"));
const Gaanap = React.lazy(() => import("../../pages/gaanap.js"));
const Toothfairy = React.lazy(() => import("../../pages/toothfairy.js"));
const Blog = React.lazy(() => import("../../pages/blog/index"));
// const ProjectDetails = React.lazy(() => import("./pages/projects-details"));
const StudyDetails = React.lazy(() =>
    import("../../pages/case-study/studyDetails.js")
);
const Privacy = React.lazy(() => import("../../pages/privacy-policy.js"));
const Term = React.lazy(() => import("../../pages/terms-conditions.jsx"));
const Service = React.lazy(() => import("../../pages/services/index"));
const BlogDetails = React.lazy(() => import("../../pages/blog/blogData.js"));
const ServiceDetails = React.lazy(() =>
    import("../../pages/services/servicesId.js")
);

const ServicePage = React.lazy(() =>
    import("../../pages/services/services-page.js")
);
const Education = React.lazy(() => import("../Industry/Education.js"));
const Healthcare = React.lazy(() => import("../Industry/Healthcare.js"));
const Ecommerce = React.lazy(() => import("../Industry/Ecommerce.js"));
const Banking = React.lazy(() => import("../Industry/Banking.js"));
const Traveling = React.lazy(() => import("../Industry/Traveling.js"));
const CaseStudy = React.lazy(() => import("../../pages/case-study/index.js"));
const Error = React.lazy(() => import("../../pages/404.js"));

export default function RoutesComponent() {
    return (
        <React.Suspense
            fallback={
                <div className="overlay">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        >
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/team" element={<Team />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/partner" element={<Partner />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/services" element={<Service />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogDetails />} />
                <Route path="/career" element={<Career />} />
                <Route path="/case-study" element={<CaseStudy />} />
                <Route path="/case-study/:page" element={<StudyDetails />} />
                <Route path="/services/:page" element={<ServiceDetails />} />
                <Route path="/services/:id/:page" element={<ServicePage />} />
                {/* <Route
                path="/projects-details/:id"
                element={<ProjectDetails />}
            /> */}
                <Route path="/cmb" element={<Cmb />} />
                <Route path="/toothfairy" element={<Toothfairy />} />
                <Route path="/gaanap" element={<Gaanap />} />

                <Route path="/education" element={<Education />} />
                <Route path="/healthcare" element={<Healthcare />} />
                <Route path="/ecommerce-retail" element={<Ecommerce />} />
                <Route path="/banking-finance" element={<Banking />} />
                <Route
                    path="/traveling-transportation"
                    element={<Traveling />}
                />

                <Route path="/terms-conditions" element={<Term />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
            <GoTop scrollStepInPx="50" delayInMs="10.50" />
        </React.Suspense>
    );
}
